import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import bannerpc from "../../Assets/newevopage/evoBanner-desktop.png";
import bannermobile from "../../Assets/newevopage/evoBannermobile.png";
import backArrow from "../../Assets/newevopage/bannerbackarrow.png";
import EvoCardWWD from "../../Components/Newdesigncomponets/EvoCardWWD/EvoCardWWD";
import wwdimg1 from "../../Assets/newevopage/evowwd-1.png";
import wwdimg2 from "../../Assets/newevopage/evowwd-2.png";
import wwdimg3 from "../../Assets/newevopage/evowwd-3.png";
import wwdimg4 from "../../Assets/newevopage/evowwd-4.png";
import wwdimg5 from "../../Assets/newevopage/evowwd-5.png";
import TextimgCard from "../../Components/Newdesigncomponets/TextimgCard/TextimgCard";
import course1 from "../../Assets/newevopage/Coding.png";
import course2 from "../../Assets/newevopage/Robotics.png";
import course3 from "../../Assets/newevopage/Drone.png";
import course4 from "../../Assets/newevopage/Python.png";
import course5 from "../../Assets/newevopage/AR-VR.png";
import course6 from "../../Assets/newevopage/HTML.png";
import course7 from "../../Assets/newevopage/Electronics.png";
import course8 from "../../Assets/newevopage/3D design and Printing.png";
import course9 from "../../Assets/newevopage/AI-ML.png";
import course10 from "../../Assets/newevopage/appdevlop.png"
import tools1 from "../../Assets/newevopage/PictoBlox.png";
import tools2 from "../../Assets/newevopage/Notepad ++.png";
import tools3 from "../../Assets/newevopage/Oneshape.png";
import tools4 from "../../Assets/newevopage/MIT APP Inventor.png";
import tools5 from "../../Assets/newevopage/Jupyter.png";
import tools6 from "../../Assets/newevopage/Unity.png";
import tools7 from "../../Assets/newevopage/arduinopng.png"
// import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LeftRightcomponent from "../../Components/Newdesigncomponets/LeftRightComponent/LeftRightcomponent";
import whyus1 from "../../Assets/newevopage/whyus-1.png";
import whyus2 from "../../Assets/newevopage/whyus-2.png";
// import outcome from "../../Assets/newevopage/outcome-Img.png";
import outcome from "../../Assets/newevopage/newimges/Why us.svg";
import ExplorMore from "../../Components/Newdesigncomponets/ExploreMore/ExplorMore";
import StemAccredition from "../../Assets/Stem-Accredition .png";
import BuyKitCard from "../../Components/Newdesigncomponets/BuykitCard/BuyKitCard";
import buykitimg from "../../Assets/newevopage/buykitimg.png";
import ReactPlayer from "react-player";
import bannernew from "../../Assets/newevopage/newimges/evoskillbanner.png";
import bannernewmob from "../../Assets/newevopage/newimges/bannermobile.png";
import whatwedo1 from "../../Assets/newevopage/newimges/1.svg";
import whatwedo2 from "../../Assets/newevopage/newimges/2.svg";
import whatwedo3 from "../../Assets/newevopage/newimges/3.svg";
import whatwedo4 from "../../Assets/newevopage/newimges/4.svg";
import whatwedo5 from "../../Assets/newevopage/newimges/5.svg";
import whyusnew1 from "../../Assets/newevopage/newimges/whyusone.svg";
import whyusnew2 from "../../Assets/newevopage/newimges/whyustwo.svg";
import buynow1 from "../../Assets/newevopage/newimges/Electronics.png";
import buynow2 from "../../Assets/newevopage/newimges/Virtual Reality.png";
import buynow3 from "../../Assets/newevopage/newimges/robotic.png";
import buynow4 from "../../Assets/newevopage/newimges/Coding.png";
import stem from "../../Assets/newevopage/newimges/stem.svg";
import dronekit from "../../Assets/newevopage/newimges/image 384.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { Snackbar, Switch } from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import { api_call } from "../../Utils/Network";
import { Button, TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Grow from "@material-ui/core/Grow";
import cf1 from "../../Assets/NewHomepageImg/cf-1.png";
import cf2 from "../../Assets/NewHomepageImg/cf-2.png";
import girl_Illustrator from "../../Assets/brainhap/girl_Illustrator.svg";
import workIcon3 from "../../Assets/NewHomepageImg/workicon-3.png";
import NewGallery from "../../Components/Newdesigncomponets/NewGallery/NewGallery";
import imgmthree from "../../Assets/NewHomepageImg/MG-img-3.png";
import imgmfour from "../../Assets/NewHomepageImg/MG-img-4.png";
import imgmfive from "../../Assets/NewHomepageImg/MG-img-5.png";
import imgmsix from "../../Assets/NewHomepageImg/MG-img-6.png";
import imgmseven from "../../Assets/NewHomepageImg/MG-img-7.png";
import imgmeight from "../../Assets/NewHomepageImg/MG-img-8.png";
import imgmnine from "../../Assets/NewHomepageImg/MG-img-9.png";
import imgthree from "../../Assets/NewHomepageImg/G-img-3.png";
import imgfour from "../../Assets/NewHomepageImg/G-img-4.png";
import imgfive from "../../Assets/NewHomepageImg/G-img-5.png";
import imgsix from "../../Assets/NewHomepageImg/G-img-6.png";
import imgseven from "../../Assets/NewHomepageImg/G-img-7.png";
import imgeight from "../../Assets/NewHomepageImg/G-img-8.png";
import imgnine from "../../Assets/NewHomepageImg/G-img-9.png";
import g1 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-1.png";
import g2 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-2.png";
import g3 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-3.png";
import g4 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-4.png";
import g5 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-5.png";
import g6 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-6.png";
import g7 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-7.png";
import g8 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-8.png";
import g9 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-9.png";
import g10 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-10.png";
import g11 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-11.png";
import g12 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-12.png";
import g13 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-13.png";
import g14 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-14.png";
import g15 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-15.png";
import g16 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-16.png";
import g17 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-17.png";
import g18 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-18.png";
import g19 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-19.png";
import g20 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-20.png";
import CustomMultiSelectChips from "../../Components/CustomMultiSelectChips/CustomMultiSelectChips";
import NSDC from "../../Assets/NewHomepageImg/NSDC.png"
import marchgimg1 from "../../Assets/marchnewImg/march2025_g1.png";
import marchgimg2 from "../../Assets/marchnewImg/march2025_g2.png";
import marchgimg3 from "../../Assets/marchnewImg/march2025_g3.png";
import marchgimg4 from "../../Assets/marchnewImg/march2025_g4.png";
import marchgimg5 from "../../Assets/marchnewImg/march2025_g5.png";
import marchgimg6 from "../../Assets/marchnewImg/march2025_g6.png";
import marchgimg7 from "../../Assets/marchnewImg/march2025_g7.png";
import marchgimg8 from "../../Assets/marchnewImg/march2025_g8.png";
import marchgimg9 from "../../Assets/marchnewImg/march2025_g9.png";
import marchgimg10 from "../../Assets/marchnewImg/march2025_g10.png";
import marchgimg11 from "../../Assets/marchnewImg/march2025_g11.png";
import marchgimg12 from "../../Assets/marchnewImg/march2025_g12.png";
import marchgimg13 from "../../Assets/marchnewImg/march2025_g13.png";
import marchgimg14 from "../../Assets/marchnewImg/march2025_g14.png";
import marchgimg15 from "../../Assets/marchnewImg/march2025_g15.png";
import marchgimg16 from "../../Assets/marchnewImg/march2025_g16.png";
import marchgimg17 from "../../Assets/marchnewImg/march2025_g17.png";
import marchgimg18 from "../../Assets/marchnewImg/march2025_g18.png";
import marchgimg19 from "../../Assets/marchnewImg/march2025_g19.png";
import marchgimg20 from "../../Assets/marchnewImg/march2025_g20.png";
import marchgimg21 from "../../Assets/marchnewImg/march2025_g21.png";
import marchgimg22 from "../../Assets/marchnewImg/march2025_g22.png";
import marchgimg23 from "../../Assets/marchnewImg/march2025_g23.png";
import marchgimg24 from "../../Assets/marchnewImg/march2025_g24.png";
import marchgimg25 from "../../Assets/marchnewImg/march2025_g25.png";

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};

function OurServices() {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [finelOpne, setFinelOpne] = useState(false);
  const playerRef = useRef(null);
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState({ name: "" });
  const [buyKit, setBuyKit] = useState();
  const [showBuykit, setShowBuykit] = useState();
  const [userData, setUserData] = useState({
    contact_person_name: "",
    // c_lname: "",
    c_phone: "",
    c_email: "",
    c_message: "",
    c_school: "",
  });
  var temp = {};

  const [orderData, setOrderData] = useState({
    full_name: "",
    phone: "",
    email: "",
    kit_id: [],
  });

  const [productError, setProductError] = useState({ name: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const vieworderboxOpne = (video) => {
    setSelectedVideo(video);
    setFinelOpne(true);
  };
  const viewVideoClose = () => setFinelOpne(false);

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.contact_person_name =
      userData.contact_person_name === "" ? "Please enter Your Name" : "";
    temp.c_phone = num.test(userData.c_phone)
      ? ""
      : "Please Enter valid number";
    // temp.c_email = userData.c_email.match(validEmail)
    //   ? ""
    //   : "Please Enter valid email";
    // temp.c_message = userData.c_message === "" ? "Please Enter valid Message" : "";
    temp.c_school = userData.c_school === "" ? "Please Enter School Name" : "";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const submitForm = () => {
    if (!validate()) return null;
    var payload = {
      contact_person_name: userData.contact_person_name,
      // last_name: userData.c_lname,
      phone: userData.c_phone,
      email: userData.c_email,
      message: userData.c_message,
      school: userData.c_school,
    };

    console.log(payload, "contactfromdata");

    // if(payload){
    //   setMsg("Thank You! Your submission has been sent.");
    // }
    // setMsg("Thank You! Yo ur submission has been sent.");
    api_call
      .post("contact/general/", payload)
      .then((x) => {
        setMsg("Thank You! Your submission has been sent.");
        setUserData({
          contact_person_name: "",
          c_phone: "",
          // c_email: "",
          // c_message: "",
          c_school: "",
        });
        document.getElementById("outlined-name").value = "";
        // document.getElementById("outlined-email").value = "";
        document.getElementById("outlined-phone").value = "";
        document.getElementById("outlined-school").value = "";
        // document.getElementById("outlined-multiline-static").value = "";
        // setSelectedGrades([]);
      })
      .catch((error) => console.log(error));
  };

  const handle = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleProduct = (e) => {
    const { name, value } = e.target;
    setOrderData({ ...orderData, [name]: value });
    setProductError({ ...productError, [name]: "" });
  };

  const handleChangeChips = (e, chipType) => {
    if (chipType === "product") {
      setOrderData({
        ...orderData,
        kit_id: typeof e === "string" ? e.split(",") : e,
      });
    }
  };
  const handleDeleteChips = (id, chipType) => {
    console.log("poli", id, chipType);
    if (chipType === "product") {
      let storeVal = orderData?.kit_id.filter((chip) => chip.id !== id);
      console.log("DelPayload", storeVal);
      setOrderData({
        ...orderData,
        kit_id: storeVal,
      });
    }
  };
  const productvalidate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.full_name = orderData.full_name === "" ? "Please enter Your Name" : "";
    temp.phone = num.test(orderData.phone) ? "" : "Please Enter valid number";
    temp.email = orderData.email.match(validEmail)
      ? ""
      : "Please Enter valid email";
    // temp.c_message = userData.c_message === "" ? "Please Enter valid Message" : "";
    // temp.c_school = userData.c_school === "" ? "Please Enter School Name" : "";
    temp.kit_id = orderData.kit_id != 0 ? "" : "Please Choose atleast one product to order";

    setProductError({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };
  const placeOrder = () => {
    if (!productvalidate()) return null;
    const productIds = orderData?.kit_id?.map((kit_id) => kit_id?.id);
    let data = {
      ...orderData,
      full_name: orderData?.full_name,
      email: orderData?.email,
      phone: orderData?.phone,
      kit_id: productIds,
    };
    console.log(data, "neworderproduct");
    api_call
      .post(`counseling/v1/order/kit/`, data)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          console.log("apihit succefull");
          setMsg("Thank You! Your submission has been sent.");
          setOrderData({
            full_name: "",
            phone: "",
            email: "",
            kit_id: [],
          });
          document.getElementById("outlined-name-order").value = "";
          // document.getElementById("outlined-email").value = "";
          document.getElementById("outlined-phone-order").value = "";
          document.getElementById("outlined-email-order").value = "";
          setFinelOpne(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const productListingApi = () => {
    api_call
      .get(`counseling/v1/kit/`)
      .then((res) => {
        console.log(res?.data?.data, "listingfisting");
        let modifiedArray = res.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });
        setShowBuykit(res?.data?.data);
        // setSubjectList([...modifiedArray]);
        setBuyKit([...modifiedArray]);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    productListingApi();
  }, []);
  return (
    <>
      <section className={styles.bannerContainer}>
        <div className={`${styles.bannerimg} ${styles.pcShow}`}>
          <img src={bannernew} />
        </div>
        <div className={`${styles.bannerimg} ${styles.mobileShow}`}>
          <img src={bannernewmob} />
        </div>
        <div className={styles.bannerText}>
          <h1>
            NEXT-GEN SKILL COURSES AT EVOSKILL LAB
            <br /> <span>Learning through Doing</span>
          </h1>
        </div>
        {/* <div className={styles.backarrowClick}>
          <img src={backArrow} />
        </div> */}
      </section>
      <section className={styles.whatwedoSection}>
        <h2 className={`${styles.sectionHeading} ${styles.pl10}`}>
          What we do
        </h2>
        <div className={styles.mobileauto}>
          <div className={styles.whatwedoCardListing}>
            {whatwedo?.map((info, index) => {
              return <EvoCardWWD cardData={info} />;
            })}
          </div>
        </div>
      </section>
      <section className={styles.videoSection}>
        <h2 className={`${styles.sectionHeading} ${styles.pl10}`}>
          Experience our Lab
        </h2>
        <div className={styles.videoContainer}>
          <ReactPlayer
            controls={true}
            ref={(player) => {
              playerRef.current = player;
            }}
            // url={selectedVideo?.file}
            url="https://youtu.be/kxF-7NECgio?si=cz7mqceKQ8JUPSb7"
            // width="100%"
            // height="100%"
            borderRadius="25px"
            className={styles.innerVideoDisplay}
            style={{
              backgroundColor: "#000",
              borderRadius: "25px",
            }}
          />
        </div>
      </section>
      <section className={styles.coursesSection}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter}`}>
          Courses Available
        </h2>
        <div className={styles.cardlisting}>
          {coursesData.map((v) => {
            return <TextimgCard values={v} />;
          })}
        </div>
      </section>
      <section className={styles.toolsSection}>
        <div className={styles.border}>
          <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
            Tools Learnt
          </h2>
          <div className={`${styles.cardlisting} `}>
            {toolsData.map((v) => {
              return <TextimgCard values={v} />;
            })}
          </div>
        </div>
      </section>
      <section className={styles.applynowSection}>
        <div className={styles.applynowtext}>
          <p>
            Become a STEM teacher with us and educate the next-generation,
            paving the way for a skilled and empowered workforce of the future.
          </p>
          <div>
            <Button variant="contained" className={styles.applynowbtn}>
              Apply Now
            </Button>
          </div>
        </div>
      </section>
      <section className={styles.whyus}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
          Why Us
        </h2>
        <h4 className={`${styles.highlighttext} ${styles.textcenter} `}>
          We offer STEM Education + 21st Century Skills
        </h4>
        <p className={`${styles.normalpara} ${styles.textcenter} `}>
          We focus on developing psychological and cognitive skills that enhance
          a student's abilities, aptitude, and areas of interest. STEM education
          is the pathway for students to explore and receive practical hands-on
          training, allowing them to learn next-generation career subjects and
          their real-life applications.
        </p>
        <p className={`${styles.heghlightpara} ${styles.textcenter} `}>
          At BrainHap, our learning approach is achieved through strategic
          planning, critical thinking, teamwork, and decision-making.
        </p>
        <LeftRightcomponent Data={whyusdata} />
      </section>

      <section className={styles.outcomeSection}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
          Why Us
        </h2>
        <div className={`${styles.outcomeimgContainer}`}>
          <img src={outcome} alt="" />
        </div>
      </section>

      <ExplorMore exploredataobj={exploreobje} />

      <section className={styles.buyOurkitsection}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
          Buy our kits
        </h2>
        <div className={styles.buycardListing}>
          {showBuykit?.map((v) => {
            return (
              <BuyKitCard values={v} vieworderboxOpne={vieworderboxOpne} />
            );
          })}
        </div>
      </section>
      <NewGallery values={Gallerydata} />
      <section
        className={styles.contactformSection}
        id="requestdemo"
        // ref={myRef}
      >
        <div className={`${styles.innercontactContainer}`}>
          <div className={styles.borderContainer}>
            <div className={styles.bgacolor}>
              <div className={styles.fromContainer}>
                <h4 className={styles.sectionHeading}>Request Demo</h4>
                <p className={styles.serviceInfopara}>
                  Be the first one to get to know about BrainHap
                </p>

                <div className={styles.textfieldContainer}>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-name"
                      variant="outlined"
                      label="Enter name"
                      placeholder="Enter name"
                      name="contact_person_name"
                      type="text"
                      className={styles.fullwidth}
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.contact_person_name}
                    </p>
                  </div>
                  {/* <div className={styles.innerbox}>
                    <TextField
                      id="outlined-email"
                      variant="outlined"
                      label="Enter email"
                      placeholder="Enter email"
                      type="text"
                      className={styles.fullwidth}
                      name="c_email"
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.c_email}
                    </p>
                  </div> */}
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-phone"
                      variant="outlined"
                      label="Mobile Number"
                      // placeholder="Mobile Number"
                      type="text"
                      name="c_phone"
                      className={styles.fullwidth}
                      style={{ textAlign: "left" }}
                      onChange={handle}
                      // maxRows="10"
                      InputProps={{
                        startAdornment: (
                          // <input type="text" value="+91 |" readOnly style={{border:"none", background:'transparent'}} />
                          <InputAdornment position="start">
                            +91 |
                          </InputAdornment>
                        ),
                      }}
                      // required
                    />
                  </div>
                  <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-school"
                      variant="outlined"
                      label="Enter school"
                      placeholder="Enter school"
                      type="text"
                      className={styles.fullwidth}
                      name="c_school"
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.c_school}
                    </p>
                  </div>
                  <div className={styles.innerbox}>
                    {/* <TextField
                      id="outlined-multiline-static"
                      label="Your message"
                      name="c_message"
                      multiline
                      className={styles.fullwidth}
                      rows={7}
                      variant="outlined"
                      style={{ margin: "20px 0" }}
                      onChange={handle}
                    /> */}
                  </div>
                  <p style={{ margin: "0", color: "red" }}>
                    {errors.c_message}
                  </p>
                </div>
                <Button
                  variant="contained"
                  className={styles.fromsubmitbtn}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div className={`${styles.contactformImgContainer}`}>
            <img src={girl_Illustrator} />
          </div>
        </div>
        <div className={`${styles.postions} ${styles.cfone}`}>
          <img src={cf1} />
        </div>
        <div className={`${styles.postions} ${styles.cftwo}`}>
          <img src={cf2} />
        </div>
        <div className={`${styles.postions} ${styles.cfthree}`}>
          <img src={workIcon3} />
        </div>
      </section>
      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={3000}
          message={msg}
          TransitionComponent={Grow}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CheckIcon className={styles.formsubmited} />
          {/* <Alert severity="success">{msg}</Alert> */}
        </Snackbar>
      )}

      <Modal
        open={finelOpne}
        onClose={viewVideoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTwo} className={styles.boxForMobile}>
          <div
            className={styles.closebtn}
            style={{ textAlign: "right", cursor: "pointer" }}
          >
            <CloseIcon onClick={viewVideoClose} />
          </div>
          {/* <div>{selectedVideo?.id}</div> */}
          <div className={styles.fromContainer}>
            <h4 className={styles.sectionHeading}>Your Order</h4>

            <div className={styles.textfieldContainer}>
              <div className={styles.innerbox}>
                <TextField
                  id="outlined-name-order"
                  variant="outlined"
                  label="Enter name"
                  placeholder="Enter name"
                  name="full_name"
                  type="text"
                  className={styles.fullwidth}
                  onChange={handleProduct}
                  // required
                />
                <p style={{ margin: "0", color: "red" }}>
                  {productError.full_name}
                </p>
              </div>
              <div className={styles.innerbox}>
                <TextField
                  id="outlined-phone-order"
                  variant="outlined"
                  label="Mobile Number"
                  // placeholder="Mobile Number"
                  type="number"
                  name="phone"
                  className={styles.fullwidth}
                  style={{ textAlign: "left" }}
                  onChange={handleProduct}
                  // maxRows="10"
                  InputProps={{
                    startAdornment: (
                      // <input type="text" value="+91 |" readOnly style={{border:"none", background:'transparent'}} />
                      <InputAdornment position="start">+91 |</InputAdornment>
                    ),
                  }}
                  // required
                />
                <p style={{ margin: "0", color: "red" }}>{productError.phone}</p>
              </div>

              <div className={styles.innerbox}>
                <TextField
                  id="outlined-email-order"
                  variant="outlined"
                  label="Enter email"
                  placeholder="Enter email"
                  type="text"
                  className={styles.fullwidth}
                  name="email"
                  onChange={handleProduct}
                  autoComplete="false"
                  // required
                />
                <p style={{ margin: "0", color: "red" }}>{productError.email}</p>
              </div>
              <div className={styles.innerbox}>
                <CustomMultiSelectChips
                  listArray={buyKit}
                  // id=""
                  label="product"
                  name="product"
                  onChange={(e) => handleChangeChips(e, "product")}
                  handleDelete={(id) => handleDeleteChips(id, "product")}
                  value={orderData?.kit_id}
                  size={"small"}
                  className={styles.fullwidth}
                  // error={productError}
                />
                <p style={{ margin: "0", color: "red" }}>{productError.kit_id}</p>
              </div>
            </div>

            <Button
              variant="contained"
              className={styles.fromsubmitbtn}
              onClick={placeOrder}
            >
              Order
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default OurServices;
const orderProduct = [
  {
    id: 1,
    title: "Electronics",
  },
  {
    id: 2,
    title: "Virtual Reality",
  },
  {
    id: 3,
    title: "Robotics",
  },
  {
    id: 4,
    title: "Drone Tech",
  },
];

const exploreobje = {
  imgOne: stem,
  textOne: "CURRICULUM DESIGN YOU CAN TRUST",
  imgTwo: "",
  textTwo: "",
};

const whatwedo = [
  {
    id: 1,
    img: whatwedo1,
    backgroundCol: "linear-gradient(180deg, #DBFEF4 0%, #BFE1E0 100%)",
    para: "Age-appropriate vocational skill courses focusing on next-gen careers.",
  },
  {
    id: 2,
    img: whatwedo2,
    backgroundCol: "linear-gradient(359deg, #C8E1C5 1.21%, #DDEBDC 99.36%)",
    para: "Carefully designed, formulated and structured content to bring out the best.",
  },
  {
    id: 3,
    img: whatwedo3,
    backgroundCol: "linear-gradient(180deg, #FBF4F4 0%, #F6E8E8 100%)",
    para: "Build 21st century skills like Critical Thinking, Logical Reasoning, Problem Solving and many more.",
  },
  {
    id: 4,
    img: whatwedo4,
    backgroundCol: "linear-gradient(180deg, #EDF3F9 0%, #D5E2F0 99.62%)",
    para: "Increasing student probability of achieving dream career.",
  },
  {
    id: 5,
    img: whatwedo5,
    backgroundCol: " linear-gradient(180deg, #EBEFF3 0%, #C8DBE2 98.84%)",
    para: "Experiential learning courses in accordance with NEP 2020.",
  },
];

const coursesData = [
  {
    id: 1,
    img: course1,
    title: "Coding",
  },
  {
    id: 2,
    img: course2,
    title: "Robotics",
  },
  {
    id: 3,
    img: course3,
    title: "Drone",
  },
  {
    id: 4,
    img: course4,
    title: "Python",
  },
  {
    id: 5,
    img: course5,
    title: "AR/VR",
  },
  {
    id: 6,
    img: course6,
    title: "HTML",
  },
  {
    id: 7,
    img: course7,
    title: "Electronics",
  },
  {
    id: 8,
    img: course8,
    title: "3D design and Printing",
  },
  {
    id: 9,
    img: course9,
    title: "AI/ML",
  },
  {
    id: 10,
    img: course10,
    title: "App Development",
  },
];

const toolsData = [
  {
    id: 1,
    img: tools1,
    title: "PictoBlox",
  },
  {
    id: 2,
    img: tools2,
    title: "Notepad ++",
  },
  {
    id: 3,
    img: tools3,
    title: "Onshape",
  },
  {
    id: 4,
    img: tools4,
    title: "MIT App Inventor",
  },
  {
    id: 5,
    img: tools5,
    title: "Jupyter",
  },
  {
    id: 6,
    img: tools6,
    title: "Unity",
  },
  {
    id: 7,
    img: tools7,
    title: "Arduino IDE",
  },
];

const whyusdata = [
  {
    id: 1,
    img: whyusnew1,
    backgroundColor:
      " linear-gradient(180deg, rgba(206, 251, 255, 0.8) 0.01%, rgba(88, 169, 170, 0.8) 100%)",
    textcolor: "#1F6C73",
    heading: "Our STEM fundamentals activities lay the groundwork for:",
    para: "Neuroscience studies have shown that psychological and cognitive skills enhancement increase the chances of academic success.",
  },
  {
    id: 2,
    img: whyusnew2,
    textcolor: "#3A559A",
    backgroundColor:
      "linear-gradient(180deg, rgba(193, 210, 255, 0.8) 0%, rgba(104, 130, 198, 0.8) 100%)",
    heading: "Next-Gen Problem Solvers: Problem-Based Learning (PBL)",
    para: "Our approach is centered on problem-based learning because it nurtures adaptable thinking – a skill crucial for future success. In this learning journey, every question is a stepping stone, and we are equipped with all the resources to guide you through simulations, missions, and problem-solving experiences.",
  },
];

const buycard = [
  {
    id: 1,
    img: buynow1,
    title: "Electronics",
    // desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
  {
    id: 2,
    img: buynow2,
    title: "Virtual Reality",
    // desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
  {
    id: 3,
    img: buynow3,
    title: "Robotics",
    // desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
  {
    id: 4,
    img: dronekit,
    title: "Drone Tech",
    // desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
];
const Gallerydata = [
  { id: 10, img: marchgimg1, mobileimg: marchgimg1 },
  { id: 11, img: marchgimg2, mobileimg: marchgimg2 },
  { id: 12, img: marchgimg3, mobileimg: marchgimg3 },
  { id: 13, img: marchgimg4, mobileimg: marchgimg4 },
  { id: 14, img: marchgimg5, mobileimg: marchgimg5 },
  { id: 15, img: marchgimg6, mobileimg: marchgimg6 },
  { id: 16, img: marchgimg7, mobileimg: marchgimg7 },
  { id: 17, img: marchgimg8, mobileimg: marchgimg8 },
  { id: 18, img: marchgimg9, mobileimg: marchgimg9 },
  { id: 19, img: marchgimg10, mobileimg: marchgimg10 },
  { id: 20, img: marchgimg11, mobileimg: marchgimg11 },
  { id: 21, img: marchgimg12, mobileimg: marchgimg12 },
  { id: 22, img: marchgimg13, mobileimg: marchgimg13 },
  { id: 23, img: marchgimg14, mobileimg: marchgimg14 },
  { id: 24, img: marchgimg15, mobileimg: marchgimg15 },
  { id: 25, img: marchgimg16, mobileimg: marchgimg16 },
  { id: 26, img: marchgimg17, mobileimg: marchgimg17 },
  { id: 27, img: marchgimg18, mobileimg: marchgimg18 },
  { id: 28, img: marchgimg19, mobileimg: marchgimg19 },
  { id: 29, img: marchgimg20, mobileimg: marchgimg20 },
  { id: 30, img: marchgimg21, mobileimg: marchgimg21 },
  { id: 31, img: marchgimg22, mobileimg: marchgimg22 },
  { id: 32, img: marchgimg23, mobileimg: marchgimg23 },
  { id: 33, img: marchgimg24, mobileimg: marchgimg24 },
  { id: 34, img: marchgimg25, mobileimg: marchgimg25 },
  { id: 35, img: imgthree, mobileimg: imgmthree },
  { id: 36, img: imgfour, mobileimg: imgmfour },
  { id: 37, img: imgfive, mobileimg: imgmfive },
  { id: 38, img: imgsix, mobileimg: imgmsix },
  { id: 39, img: imgseven, mobileimg: imgmseven },
  { id: 40, img: imgeight, mobileimg: imgmeight },
  { id: 41, img: imgnine, mobileimg: imgmnine },
  { id: 42, img: g1, mobileimg: g1 },
  { id: 43, img: g2, mobileimg: g2 },
  { id: 44, img: g3, mobileimg: g3 },
  { id: 45, img: g4, mobileimg: g4 },
  { id: 46, img: g5, mobileimg: g5 },
  { id: 47, img: g6, mobileimg: g6 },
  { id: 48, img: g7, mobileimg: g7 },
  { id: 49, img: g8, mobileimg: g8 },
  { id: 50, img: g9, mobileimg: g9 },
  { id: 51, img: g10, mobileimg: g10 },
  { id: 52, img: g11, mobileimg: g11 },
  { id: 53, img: g12, mobileimg: g12 },
  { id: 54, img: g13, mobileimg: g13 },
  { id: 55, img: g14, mobileimg: g14 },
  { id: 56, img: g16, mobileimg: g16 },
  { id: 57, img: g17, mobileimg: g17 },
  { id: 58, img: g18, mobileimg: g18 },
  { id: 59, img: g19, mobileimg: g19 },
  { id: 60, img: g20, mobileimg: g20 },
];
