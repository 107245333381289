import React, { useEffect } from 'react'
import styles from "./index.module.css"
import NewGallery from '../../Components/Newdesigncomponets/NewGallery/NewGallery'
import NewReviewsCarousel from '../../Components/Newdesigncomponets/NewReviewsCarousel/NewReviewsCarousel'
import RequestDemoForm from '../../Components/Newdesigncomponets/RequestDemoForm/RequestDemoForm'
import imgthree from "../../Assets/NewHomepageImg/G-img-3.png";
import imgfour from "../../Assets/NewHomepageImg/G-img-4.png";
import imgfive from "../../Assets/NewHomepageImg/G-img-5.png";
import imgsix from "../../Assets/NewHomepageImg/G-img-6.png";
import imgseven from "../../Assets/NewHomepageImg/G-img-7.png";
import imgeight from "../../Assets/NewHomepageImg/G-img-8.png";
import imgnine from "../../Assets/NewHomepageImg/G-img-9.png";
import imgmthree from "../../Assets/NewHomepageImg/MG-img-3.png";
import imgmfour from "../../Assets/NewHomepageImg/MG-img-4.png";
import imgmfive from "../../Assets/NewHomepageImg/MG-img-5.png";
import imgmsix from "../../Assets/NewHomepageImg/MG-img-6.png";
import imgmseven from "../../Assets/NewHomepageImg/MG-img-7.png";
import imgmeight from "../../Assets/NewHomepageImg/MG-img-8.png";
import imgmnine from "../../Assets/NewHomepageImg/MG-img-9.png";
import g1 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-1.png";
import g2 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-2.png";
import g3 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-3.png";
import g4 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-4.png";
import g5 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-5.png";
import g6 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-6.png";
import g7 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-7.png";
import g8 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-8.png";
import g9 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-9.png";
import g10 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-10.png";
import g11 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-11.png";
import g12 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-12.png";
import g13 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-13.png";
import g14 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-14.png";
import g15 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-15.png";
import g16 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-16.png";
import g17 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-17.png";
import g18 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-18.png";
import g19 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-19.png";
import g20 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-20.png";
import psicon1 from "../../Assets/NewHomepageImg/ps-1.png";
import psicon2 from "../../Assets/NewHomepageImg/ps-2.png";
import teacherpic from "../../Assets/NewHomepageImg/newhowdoesitwork/reviwes-pic.png";
import teacherpicmob from "../../Assets/NewHomepageImg/newhowdoesitwork/formobilreview.png";
import NeuroStudentNeedCard from '../../Components/Newdesigncomponets/NeuroStudentNeedCard/NeuroStudentNeedCard'
import cardImg1 from "../../Assets/neuromentorPage/nstCard-1.png"
import cardImg2 from "../../Assets/neuromentorPage/nstCard-2.png"
import cardImg3 from "../../Assets/neuromentorPage/nstCard-3.png"
import banner from "../../Assets/neuromentorPage/neuroBanner.png"
import bannerMobil from "../../Assets/neuromentorPage/mobileBanner.png"
import fscard1 from "../../Assets/neuromentorPage/DR.png"
import fscard2 from "../../Assets/neuromentorPage/CF.png"
import fscard3 from "../../Assets/neuromentorPage/NGF.png"
import NeuroForSuccessCard from '../../Components/Newdesigncomponets/NeuroForSuccessCard/NeuroForSuccessCard'
import img1 from "../../Assets/neuromentorPage/img-1.png"
import img2 from "../../Assets/neuromentorPage/img-2.png"
import img3 from "../../Assets/neuromentorPage/img-3.png"
import img4 from "../../Assets/neuromentorPage/img-4.png"
import homw_imgdes from "../../Assets/neuromentorPage/desktop_homw.png"
import homw_imgmob from "../../Assets/neuromentorPage/mobile_homw.png"
import workIcon1 from "../../Assets/NewHomepageImg/workicon-1.png";
import marchgimg1 from "../../Assets/marchnewImg/march2025_g1.png";
import marchgimg2 from "../../Assets/marchnewImg/march2025_g2.png";
import marchgimg3 from "../../Assets/marchnewImg/march2025_g3.png";
import marchgimg4 from "../../Assets/marchnewImg/march2025_g4.png";
import marchgimg5 from "../../Assets/marchnewImg/march2025_g5.png";
import marchgimg6 from "../../Assets/marchnewImg/march2025_g6.png";
import marchgimg7 from "../../Assets/marchnewImg/march2025_g7.png";
import marchgimg8 from "../../Assets/marchnewImg/march2025_g8.png";
import marchgimg9 from "../../Assets/marchnewImg/march2025_g9.png";
import marchgimg10 from "../../Assets/marchnewImg/march2025_g10.png";
import marchgimg11 from "../../Assets/marchnewImg/march2025_g11.png";
import marchgimg12 from "../../Assets/marchnewImg/march2025_g12.png";
import marchgimg13 from "../../Assets/marchnewImg/march2025_g13.png";
import marchgimg14 from "../../Assets/marchnewImg/march2025_g14.png";
import marchgimg15 from "../../Assets/marchnewImg/march2025_g15.png";
import marchgimg16 from "../../Assets/marchnewImg/march2025_g16.png";
import marchgimg17 from "../../Assets/marchnewImg/march2025_g17.png";
import marchgimg18 from "../../Assets/marchnewImg/march2025_g18.png";
import marchgimg19 from "../../Assets/marchnewImg/march2025_g19.png";
import marchgimg20 from "../../Assets/marchnewImg/march2025_g20.png";
import marchgimg21 from "../../Assets/marchnewImg/march2025_g21.png";
import marchgimg22 from "../../Assets/marchnewImg/march2025_g22.png";
import marchgimg23 from "../../Assets/marchnewImg/march2025_g23.png";
import marchgimg24 from "../../Assets/marchnewImg/march2025_g24.png";
import marchgimg25 from "../../Assets/marchnewImg/march2025_g25.png";

function Neuromentor({ myRef, executeScroll = () => { } }) {
    useEffect(() =>{
        window.scrollTo(0,0)
    },[])
    return (
        <>
            <section className={styles.bannerContainer}>
                <img className={styles.desktopbanner} src={banner} alt='banner' />
                <img className={styles.mobilebanner} src={bannerMobil} alt='banner' />
                <div className={styles.wetext}>
                    <h1>We</h1>
                    <p>Maximize Performance <br />  Manage Expectations <br /> Optimal Careers </p>
                </div>
                <p className={styles.bannertagLine}>
                    Our <span>trained mentor </span> will provide proper guidance for guaranteed <span> improvement</span> using <span> neurotechnology.</span>
                </p>
            </section>


            <section className={styles.studentNeeds}>
                <h3 className={`${styles.bluiesh} ${styles.featuresdiv} ${styles.textCenter}`}>
                    What do the students need?
                </h3>
                <div className={styles.StudentNeedCardListing}>
                    {
                        studentneedsData?.map((v) => {
                            return (
                                <>
                                    <NeuroStudentNeedCard values={v} />
                                </>
                            )
                        })
                    }
                </div>
            </section>


            {/* How our mentoring works */}
            <section className={styles.howMentoringContainer}>
                <div className={styles.leftContainer}>
                    {/* <div className={styles.HMimgContainer1}>
                        <img src={img1} alt='' />
                    </div>
                    <div className={styles.HMimgContainer2}>
                        <img src={img2} alt='' />
                    </div>
                    <div className={styles.HMimgContainer3}>
                        <img src={img3} alt='' />
                    </div>
                    <div className={styles.HMimgContainer4}>
                        <img src={img4} alt='' />
                    </div> */}
                    <img className={styles.desktopView} src={homw_imgdes} alt='HOMW' />
                    <img className={styles.mobileView} src={homw_imgmob} alt='HOMW' />
                </div>
                <div className={styles.rightContainer}>
                    <h2 className={styles.HMheading}>
                        HOW OUR MENTORING WORKS
                    </h2>

                    <p className={styles.hmdesc}>
                        We uses <span>Reformative Counselling</span> approach, focusing on improving students skills, aptitude and academics. This enhances brain-cognitive abilities and exam preparedness through personalized, high-accuracy methods, continual monitoring, and follow-up sessions.

                    </p>
                </div>
                <div className={`${styles.bulbimg}`}>
                    <img src={workIcon1} alt='bg-icon' />
                </div>
            </section>


            <section className={styles.forSuccssContainer}>
                <h2 className={styles.successHeading}>
                    <span>Neuromentors <br /> </span> For Success
                </h2>
                <p className={styles.successDesc}>Our qualified NEUROMENTORS treat student development as an integral part of counselling. In this, they are unique. They raise the bar for what is expected of counsellors with their training in brainmapping.
                </p>
                <div className={styles.successCardListing}>
                    {
                        successData?.map((v) => {
                            return (
                                <>
                                    <NeuroForSuccessCard values={v} />
                                </>
                            )
                        })
                    }
                </div>
            </section>

            <NewGallery values={Gallerydata} />

            <section className={styles.patnerSchoolSection}>
                <div className={` ${styles.mobile_container} `}>
                    <div className={`${styles.textCenter}`}>
                        <h3 className={`${styles.bluiesh} ${styles.featuresdiv}`}>

                            {/* Reviews from Our Partner Schools */}
                            School Testimonials
                        </h3>
                    </div>
                    {/* <NewCarousel sqd={[testimonialData]}></NewCarousel> */}
                    <NewReviewsCarousel sqd={[testimonialData]} />
                    <div className={`${styles.postions} ${styles.psicons1}`}>
                        <img src={psicon1} />
                    </div>
                    <div className={`${styles.postions} ${styles.psicons2}`}>
                        <img src={psicon2} />
                    </div>
                </div>
            </section>
            <div ref={myRef}>
                <RequestDemoForm />
            </div>
        </>
    )
}

export default Neuromentor

const Gallerydata = [
    { id: 10, img: marchgimg1, mobileimg: marchgimg1 },
    { id: 11, img: marchgimg2, mobileimg: marchgimg2 },
    { id: 12, img: marchgimg3, mobileimg: marchgimg3 },
    { id: 13, img: marchgimg4, mobileimg: marchgimg4 },
    { id: 14, img: marchgimg5, mobileimg: marchgimg5 },
    { id: 15, img: marchgimg6, mobileimg: marchgimg6 },
    { id: 16, img: marchgimg7, mobileimg: marchgimg7 },
    { id: 17, img: marchgimg8, mobileimg: marchgimg8 },
    { id: 18, img: marchgimg9, mobileimg: marchgimg9 },
    { id: 19, img: marchgimg10, mobileimg: marchgimg10 },
    { id: 20, img: marchgimg11, mobileimg: marchgimg11 },
    { id: 21, img: marchgimg12, mobileimg: marchgimg12 },
    { id: 22, img: marchgimg13, mobileimg: marchgimg13 },
    { id: 23, img: marchgimg14, mobileimg: marchgimg14 },
    { id: 24, img: marchgimg15, mobileimg: marchgimg15 },
    { id: 25, img: marchgimg16, mobileimg: marchgimg16 },
    { id: 26, img: marchgimg17, mobileimg: marchgimg17 },
    { id: 27, img: marchgimg18, mobileimg: marchgimg18 },
    { id: 28, img: marchgimg19, mobileimg: marchgimg19 },
    { id: 29, img: marchgimg20, mobileimg: marchgimg20 },
    { id: 30, img: marchgimg21, mobileimg: marchgimg21 },
    { id: 31, img: marchgimg22, mobileimg: marchgimg22 },
    { id: 32, img: marchgimg23, mobileimg: marchgimg23 },
    { id: 33, img: marchgimg24, mobileimg: marchgimg24 },
    { id: 34, img: marchgimg25, mobileimg: marchgimg25 },
    { id: 35, img: imgthree, mobileimg: imgmthree },
    { id: 36, img: imgfour, mobileimg: imgmfour },
    { id: 37, img: imgfive, mobileimg: imgmfive },
    { id: 38, img: imgsix, mobileimg: imgmsix },
    { id: 39, img: imgseven, mobileimg: imgmseven },
    { id: 40, img: imgeight, mobileimg: imgmeight },
    { id: 41, img: imgnine, mobileimg: imgmnine },
    { id: 42, img: g1, mobileimg: g1 },
    { id: 43, img: g2, mobileimg: g2 },
    { id: 44, img: g3, mobileimg: g3 },
    { id: 45, img: g4, mobileimg: g4 },
    { id: 46, img: g5, mobileimg: g5 },
    { id: 47, img: g6, mobileimg: g6 },
    { id: 48, img: g7, mobileimg: g7 },
    { id: 49, img: g8, mobileimg: g8 },
    { id: 50, img: g9, mobileimg: g9 },
    { id: 51, img: g10, mobileimg: g10 },
    { id: 52, img: g11, mobileimg: g11 },
    { id: 53, img: g12, mobileimg: g12 },
    { id: 54, img: g13, mobileimg: g13 },
    { id: 55, img: g14, mobileimg: g14 },
    { id: 56, img: g16, mobileimg: g16 },
    { id: 57, img: g17, mobileimg: g17 },
    { id: 58, img: g18, mobileimg: g18 },
    { id: 59, img: g19, mobileimg: g19 },
    { id: 60, img: g20, mobileimg: g20 },
  ];

const testimonialData = [
    {
        id: 1,
        name: "Pragya Singh",
        position: "Principal",
        institute_name: "Composite Vidyalaya Kalyanpur",
        video: "https://prod-api.brainhap.com/files/media/2022/06/03/pragya.mp4",
        icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_1.png",
        icon2: teacherpic,
        icon3: teacherpicmob,
        text: "The students gave the test with a lot of enthusiasm",
        rating: 4,
        order: 1,
    },
    {
        id: 2,
        name: "Archana Dwivedi",
        position: "Principal",
        institute_name: "Ups Kanyavidyalay kanpur",
        video:
            "https://prod-api.brainhap.com/files/media/2022/06/03/WhatsApp_Video_2022-05-18_at_1.31.46_PM.mp4",
        icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_2.png",
        icon2: teacherpic,
        icon3: teacherpicmob,
        text: "The test is a great initiative for the students' benefit",
        rating: 5,
        order: 1,
    },
    {
        id: 3,
        name: "Sachin sir",
        position: "Principal",
        institute_name: "Chinchavali English Mumbai school",
        video: "https://prod-api.brainhap.com/files/media/2022/06/03/sachin.mp4",
        icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_mhFN1Jx.png",
        icon2: teacherpic,
        icon3: teacherpicmob,
        text: "The test is a great opportunity for students today as there are many career options but no guidance from anyone",
        rating: 4,
        order: 1,
    },
];

const studentneedsData = [
    {
        id: 1,
        title: "KNOWLEDGE",
        details: "About areas where they lack.",
        img: cardImg1,
    },
    {
        id: 2,
        title: "COUNSELLING",
        details: "About how to improve in those areas.",
        img: cardImg2,
    },
    {
        id: 3,
        title: "TRAINING",
        details: "To reach their goal faster.",
        img: cardImg3,
    },
]

const successData = [
    {
        id: 1,
        title: "Detailed Reporting",
        details: "After each session, they  make detailed reports  that are shared with all.",
        img: fscard1,
    },
    {
        id: 2,
        title: "Constant Mentoring",
        details: "They constantly maintain the student’s upward progress.",
        img: fscard2,
    },
    {
        id: 3,
        title: "Next-Gen Focus",
        details: "They give guidance to  develop skills that matter in next-gen careers.",
        img: fscard3,
    },
]