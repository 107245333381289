import React, { useEffect, useRef } from "react";
import styles from "./alpha.module.css";
import RequestDemoForm from "../../Components/Newdesigncomponets/RequestDemoForm/RequestDemoForm";
import NewGallery from "../../Components/Newdesigncomponets/NewGallery/NewGallery";
import imgthree from "../../Assets/NewHomepageImg/G-img-3.png";
import imgfour from "../../Assets/NewHomepageImg/G-img-4.png";
import imgfive from "../../Assets/NewHomepageImg/G-img-5.png";
import imgsix from "../../Assets/NewHomepageImg/G-img-6.png";
import imgseven from "../../Assets/NewHomepageImg/G-img-7.png";
import imgeight from "../../Assets/NewHomepageImg/G-img-8.png";
import imgnine from "../../Assets/NewHomepageImg/G-img-9.png";
import imgmthree from "../../Assets/NewHomepageImg/MG-img-3.png";
import imgmfour from "../../Assets/NewHomepageImg/MG-img-4.png";
import imgmfive from "../../Assets/NewHomepageImg/MG-img-5.png";
import imgmsix from "../../Assets/NewHomepageImg/MG-img-6.png";
import imgmseven from "../../Assets/NewHomepageImg/MG-img-7.png";
import imgmeight from "../../Assets/NewHomepageImg/MG-img-8.png";
import imgmnine from "../../Assets/NewHomepageImg/MG-img-9.png";
import g1 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-1.png";
import g2 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-2.png";
import g3 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-3.png";
import g4 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-4.png";
import g5 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-5.png";
import g6 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-6.png";
import g7 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-7.png";
import g8 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-8.png";
import g9 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-9.png";
import g10 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-10.png";
import g11 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-11.png";
import g12 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-12.png";
import g13 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-13.png";
import g14 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-14.png";
import g15 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-15.png";
import g16 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-16.png";
import g17 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-17.png";
import g18 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-18.png";
import g19 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-19.png";
import g20 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-20.png";
import supp1 from "../../Assets/HomeRewamp/sir.jpeg";
import ricon1 from "../../Assets/NewHomepageImg/rIcon-1.png";
import ricon2 from "../../Assets/NewHomepageImg/ricon-2.png";
import ricon3 from "../../Assets/NewHomepageImg/ricon-3.png";
import banner3 from "../../Assets/NewHomepageImg/newhowdoesitwork/banner-iitblogo.png";
import { Button, TextField } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";
import alphaBanner from "../../Assets/alphaguide/alphaGuidebanner.svg";
import genalpha from "../../Assets/alphaguide/genalpah.svg";
import genalpha2 from "../../Assets/alphaguide/genalpha2.svg";
import careermap from "../../Assets/alphaguide/Rectangle 31150.svg";
import AlphaguideOverview from "../../Components/Newdesigncomponets/AlphaguideOverview/AlphaguideOverview";
import careercoun from "../../Assets/alphaguide/coreercounmain.svg";
import alphone from "../../Assets/alphaguide/BHAP-Brochure-Last-Online-Version-(1)-32 1.png";
import alphtwo from "../../Assets/alphaguide/BHAP-Brochure-Last-Online-Version-(1)-4 2.png";
import HowdoesitworkComponent from "../../Components/Newdesigncomponets/HowdoesitworkComponent/HowdoesitworkComponent";
// import test1 from "../../Assets/alphaguide/aptitude-3.png"
// import test2 from "../../Assets/alphaguide/interest-1.png"
// import test3 from "../../Assets/alphaguide/Academic-2.png"
import test1 from "../../Assets/alphaguide/aptitude-3.svg";
// import test2 from "../../Assets/alphaguide/interest-1.svg";
import test2 from "../../Assets/alphaguide/interest-2.png"
import test3 from "../../Assets/alphaguide/Academic-2.svg";
import marchgimg1 from "../../Assets/marchnewImg/march2025_g1.png";
import marchgimg2 from "../../Assets/marchnewImg/march2025_g2.png";
import marchgimg3 from "../../Assets/marchnewImg/march2025_g3.png";
import marchgimg4 from "../../Assets/marchnewImg/march2025_g4.png";
import marchgimg5 from "../../Assets/marchnewImg/march2025_g5.png";
import marchgimg6 from "../../Assets/marchnewImg/march2025_g6.png";
import marchgimg7 from "../../Assets/marchnewImg/march2025_g7.png";
import marchgimg8 from "../../Assets/marchnewImg/march2025_g8.png";
import marchgimg9 from "../../Assets/marchnewImg/march2025_g9.png";
import marchgimg10 from "../../Assets/marchnewImg/march2025_g10.png";
import marchgimg11 from "../../Assets/marchnewImg/march2025_g11.png";
import marchgimg12 from "../../Assets/marchnewImg/march2025_g12.png";
import marchgimg13 from "../../Assets/marchnewImg/march2025_g13.png";
import marchgimg14 from "../../Assets/marchnewImg/march2025_g14.png";
import marchgimg15 from "../../Assets/marchnewImg/march2025_g15.png";
import marchgimg16 from "../../Assets/marchnewImg/march2025_g16.png";
import marchgimg17 from "../../Assets/marchnewImg/march2025_g17.png";
import marchgimg18 from "../../Assets/marchnewImg/march2025_g18.png";
import marchgimg19 from "../../Assets/marchnewImg/march2025_g19.png";
import marchgimg20 from "../../Assets/marchnewImg/march2025_g20.png";
import marchgimg21 from "../../Assets/marchnewImg/march2025_g21.png";
import marchgimg22 from "../../Assets/marchnewImg/march2025_g22.png";
import marchgimg23 from "../../Assets/marchnewImg/march2025_g23.png";
import marchgimg24 from "../../Assets/marchnewImg/march2025_g24.png";
import marchgimg25 from "../../Assets/marchnewImg/march2025_g25.png";

function AlphaGuide() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className={`${styles.BannerConatiner}`}>
        <div className={`${styles.bannerImgContainer}`}>
          <img src={alphaBanner} alt="" />
        </div>
        <div className={styles.textContainer}>
          <h1 className={`${styles.bannertextmain} ${styles.center}`}>
            The measure of intelligence is the ability to change
          </h1>
          <h5 className={`${styles.bannertextsmall} ${styles.center}`}>
            -Dr Albert Einstein
          </h5>
        </div>
      </section>
      <section className={`${styles.alphaTC}`}>
        <h4 className={`${styles.sectionHeading} ${styles.center}`}>
          ALPHAGUIDE TEST AND COUNSELLING
        </h4>
        <h5 className={`${styles.secondHeding}`}>
          <span className={`${styles.textOne}`}>Career Mapping Test</span>
          <span className={`${styles.texttwo}`}>Career Counselling</span>
        </h5>
        <div className={`${styles.likeabutton}`}>
          <span>Grade 6-12</span>
        </div>
        <div className={`${styles.genalphSection}`}>
          <div className={`${styles.genimgContainer}`}>
            <img src={alphone} alt="" />
          </div>
          <div className={`${styles.genTextContainer}`}>
            <h6 className={`${styles.genCHeading}`}>GENERATION-ALPHA</h6>
            <h5 className={`${styles.innersecondHeding}`}>2030 IS NOT FAR</h5>
            <p className={`${styles.genpara}`}>
              Artificial Intelligence (AI), Augmented Reality (AR), Drone Tech,
              and Robotics have already become established new career fields
              with many specializations. To find your path through this maze of
              next-gen careers by the year 2030, “Generation Alpha”, born in the
              21st Century, won’t just need Career Counselling... they will need{" "}
              <span className={styles.boldertext}>
                Career Mentoring. And that is where BrainHap comes in.
              </span>
            </p>
            <h5 className={`${styles.innersecondHeding}`}>
              Students have to prepare for <span> A FUTURE JOB ROLE </span>{" "}
              THAT’S DIFFICULT TO PREDICT.
            </h5>
            <div className={styles.genbtnContainer}>
              <Button
                variant="contained"
                className={styles.btnGradient}
                // onClick={() => {
                //   redirects();
                // }}

                onClick={executeScroll}
              >
                Request Demo
                <ArrowForward />
              </Button>
            </div>
          </div>
        </div>
        <div className={`${styles.genalphSection}`}>
          <div className={`${styles.genTextContainer}`}>
            <h6 className={`${styles.genCHeading}`}>
              WHY CAREER MAPPING TEST AND CAREER COUNSELLING?
            </h6>
            <div className={`${styles.geninnerimgtext}`}>
              <h5 className={`${styles.innersecondHeding}`}>
                DESIGNED BY PSYCHOLOGISTS, APPROVED BY IIT BOMBAY
              </h5>
              <div className={styles.genInnerimgcontainer}>
                <img src={banner3} alt="iit" />
              </div>
            </div>
            {/* </div> */}
            <p className={`${styles.genpara}`}>
              Today, jobs are changing fast. Students don’t have necessary
              knowledge of themselves. They don’t know their skillsets and
              aptitude and that’s why they will struggle in their job role right
              away. Having the right skill and aptitude that are required for a
              job plays a crucial part in ensuring the success in chosen
              careers.
            </p>
            <h5 className={`${styles.innersecondHeding}`}>
              Our ambition is that every student chooses a career path that is
              ideal for them and also have the tools necessary to succeed in
              that career. 
            </h5>
            <div className={styles.genbtnContainer}>
              <Button
                variant="contained"
                className={styles.btnGradient}
                // onClick={() => {
                //   redirects();
                // }}

                onClick={executeScroll}
              >
                Request Demo
                <ArrowForward />
              </Button>
            </div>
          </div>
          <div className={`${styles.genimgContainer}`}>
            <img src={alphtwo} alt="" />
          </div>
        </div>
      </section>
      <section className={`${styles.alphaOverview}`}>
        <h4 className={`${styles.sectionHeading} ${styles.center}`}>
          ALPHAGUIDE OVERVIEW
        </h4>
        <h5 className={`${styles.secondHeding}`}>CAREER MAPPING TEST</h5>
        <p className={`${styles.sectionPara}`}>
          The test consists of three unique questionnaires for career mapping.
        </p>
        {aocardData?.map((v) => {
          return <AlphaguideOverview values={v} />;
        })}
        {/* {aocardData?.map((v) => {
          return <HowdoesitworkComponent values={v} />;
        })} */}
      </section>
      <section className={styles.ccSection}>
        {/* <h4 className={`${styles.sectionHeading} ${styles.center}`}>
          CAREER COUNSELLING
        </h4> */}
        <h5 className={`${styles.secondHeding}`}>CAREER COUNSELLING</h5>
        <div className={`${styles.ccInnercontainer}`}>
          <div className={`${styles.ccText}`}>
            <p style={{textAlign:"justify"}}>
              An online career counsellor will conduct a detailed career
              counselling session and provide a thorough analysis of the career
              mapping test results in the context of student’s stated dream
              career. The counsellor will present a conclusion as to the
              student’s current ability to achieve their dream career and
              recommend further steps like vocational skill courses to improve
              their chances of success. The counsellor will also suggest other
              careers suitable for the student so that they are aware of their
              options. All of the analyses, conclusions and recommendations will
              be given in a detailed report. 
            </p>
            <Button
              variant="contained"
              className={styles.btnGradient}
              // onClick={() => {
              //   redirects();
              // }}

              onClick={executeScroll}
            >
              Request Demo
              <ArrowForward />
            </Button>
          </div>
          <div className={styles.ccImageContainer}>
            <img src={careercoun} alt="" />
          </div>
        </div>
      </section>
      <section className={styles.LJOS}>
        <h4 className={`${styles.sectionHeading} ${styles.center}`}>
          Our Scientific Advisor & Mentor
        </h4>

        <div className={styles.mainforflex}>
          <div className={styles.LJOSimgContainer}>
            <img src={supp1} alt="" />
          </div>
          <div className={styles.textrightcontainer}>
            <h2 className={styles.hisName}>DR. AZIZUDDIN KHAN </h2>
            <div className={styles.iitslogoandname}>
              <div className={styles.iitslogoConatiner}>
                <img src={banner3} alt="iit" />
              </div>
              <h4 className={styles.iitsName}>IIT BOMBAY</h4>
            </div>
            <h4 className={styles.iitsName}>
              Cognitive Psychology and Psychophysiology{" "}
            </h4>
            <h4 className={styles.iitsName}>24+ years of experience</h4>
            <p className={styles.hisQuote}>
              “BrainHap, uses one of the best approaches to reform learning
              patterns in young students.”
            </p>
          </div>
        </div>
        <div className={styles.LJOSbtnContainer}>
          <Button
            variant="contained"
            className={styles.btnGradient}
            onClick={executeScroll}
          >
            Request Demo
            <ArrowForward />
          </Button>
        </div>
        <div className={`${styles.postions} ${styles.riconone}`}>
          <img src={ricon1} />
        </div>
        <div className={`${styles.postions} ${styles.ricontwo}`}>
          <img src={ricon2} />
        </div>
        <div className={`${styles.postions} ${styles.riconthree}`}>
          <img src={ricon3} />
        </div>
      </section>
      <NewGallery values={Gallerydata} />
      <div ref={myRef}>
        <RequestDemoForm />
      </div>
    </>
  );
}

export default AlphaGuide;

const aocardData = [
  {
    id: 1,
    img: test1,
    title: "Aptitude Test",
    description:
      "These tests are used to assess an individual's innate abilities or potential to learn skills. They can measure a wide range of abilities, such as verbal, numerical, and spatial reasoning, as well as mechanical, clerical, and technical abilities.",
    textcol: "#CA882E",
    bgcolor: "linear-gradient(269.85deg, #FFE3BD 0.64%, #F8F0E5 99.87%)",
    boxShadow: "0px 2px 20px 5px #FFE4BD",
  },
  {
    id: 2,
    img: test2,
    title: "Interest Test",
    description:
      "They are used to identify potential career paths or majors that align with their interests.",
    textcol: "#2378A7",
    bgcolor: "linear-gradient(270deg, #A8D2E9 0%, #E0F4FF 100.97%)",
    boxShadow: "0px 2px 20px 5px #A9D3EA",
  },
  {
    id: 3,
    img: test3,
    title: "Academic Test",
    description:
      "Academic tests are used to assess an individual's knowledge and skills in a specific subject or academic area.",
    textcol: "#C48989",
    bgcolor: "linear-gradient(90deg, #FAF2F2 0%, #FAD7D7 97.54%)",
    boxShadow: "0px 2px 20px 5px #FBD7D7",
  },
];

const Gallerydata = [
  { id: 10, img: marchgimg1, mobileimg: marchgimg1 },
  { id: 11, img: marchgimg2, mobileimg: marchgimg2 },
  { id: 12, img: marchgimg3, mobileimg: marchgimg3 },
  { id: 13, img: marchgimg4, mobileimg: marchgimg4 },
  { id: 14, img: marchgimg5, mobileimg: marchgimg5 },
  { id: 15, img: marchgimg6, mobileimg: marchgimg6 },
  { id: 16, img: marchgimg7, mobileimg: marchgimg7 },
  { id: 17, img: marchgimg8, mobileimg: marchgimg8 },
  { id: 18, img: marchgimg9, mobileimg: marchgimg9 },
  { id: 19, img: marchgimg10, mobileimg: marchgimg10 },
  { id: 20, img: marchgimg11, mobileimg: marchgimg11 },
  { id: 21, img: marchgimg12, mobileimg: marchgimg12 },
  { id: 22, img: marchgimg13, mobileimg: marchgimg13 },
  { id: 23, img: marchgimg14, mobileimg: marchgimg14 },
  { id: 24, img: marchgimg15, mobileimg: marchgimg15 },
  { id: 25, img: marchgimg16, mobileimg: marchgimg16 },
  { id: 26, img: marchgimg17, mobileimg: marchgimg17 },
  { id: 27, img: marchgimg18, mobileimg: marchgimg18 },
  { id: 28, img: marchgimg19, mobileimg: marchgimg19 },
  { id: 29, img: marchgimg20, mobileimg: marchgimg20 },
  { id: 30, img: marchgimg21, mobileimg: marchgimg21 },
  { id: 31, img: marchgimg22, mobileimg: marchgimg22 },
  { id: 32, img: marchgimg23, mobileimg: marchgimg23 },
  { id: 33, img: marchgimg24, mobileimg: marchgimg24 },
  { id: 34, img: marchgimg25, mobileimg: marchgimg25 },
  { id: 35, img: imgthree, mobileimg: imgmthree },
  { id: 36, img: imgfour, mobileimg: imgmfour },
  { id: 37, img: imgfive, mobileimg: imgmfive },
  { id: 38, img: imgsix, mobileimg: imgmsix },
  { id: 39, img: imgseven, mobileimg: imgmseven },
  { id: 40, img: imgeight, mobileimg: imgmeight },
  { id: 41, img: imgnine, mobileimg: imgmnine },
  { id: 42, img: g1, mobileimg: g1 },
  { id: 43, img: g2, mobileimg: g2 },
  { id: 44, img: g3, mobileimg: g3 },
  { id: 45, img: g4, mobileimg: g4 },
  { id: 46, img: g5, mobileimg: g5 },
  { id: 47, img: g6, mobileimg: g6 },
  { id: 48, img: g7, mobileimg: g7 },
  { id: 49, img: g8, mobileimg: g8 },
  { id: 50, img: g9, mobileimg: g9 },
  { id: 51, img: g10, mobileimg: g10 },
  { id: 52, img: g11, mobileimg: g11 },
  { id: 53, img: g12, mobileimg: g12 },
  { id: 54, img: g13, mobileimg: g13 },
  { id: 55, img: g14, mobileimg: g14 },
  { id: 56, img: g16, mobileimg: g16 },
  { id: 57, img: g17, mobileimg: g17 },
  { id: 58, img: g18, mobileimg: g18 },
  { id: 59, img: g19, mobileimg: g19 },
  { id: 60, img: g20, mobileimg: g20 },
];
